body {
  color: #333;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 1;
  margin: 10% 0 8%;
  text-align: center;
  width: auto;
}

/* Header */

.avatar {
  border-radius: 100%;
  width: 200px;
  height: auto;
}

.full-name {
  font-weight: 300;
}

.full-name b {
  font-weight: bold;
}

.about {
  color: #646464;
  font-size: 87.5%;
  padding-bottom: 14px;
  line-height: .6cm;
}

/* Main */

.social-links {
  list-style-type: none;
  padding: 0;
}

a:hover {
  color: #3064a9;
}

.social-links li {
  display: inline;
  padding: 0 15px;
}

.social-links li a {
  color: #BBB;
}

.social-links li a:hover {
  color: #307EA9;
}

.cv-button {
  border-radius: 3px;
  background-color: #efefef;
  text-decoration: none;
  text-align: center;
  display: block;
  margin: 1.5rem auto;
  width: 220px;
  font-size: 1.375rem;
  font-weight: 300;
  color: #333;
  line-height: 55px;
  transition: (all .2s ease);
}

.cv-button:hover {
  background-color: #333;
  color: #fff;
}
